/* AlertModal.module.css */

.modalContainer {
    position: fixed;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the modal */
    background-color: white;
    padding: 20px;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 300px; /* Adjust width as needed */
    max-width: 80%; /* Maximum width */
  }
  
  .modalTitle {
    color: #8a3ab9; /* Blue title color */
    margin-bottom: 10px;
  }
  
  .modalContent {
    margin-bottom: 10px;
  }
  
  .modalActions {
    text-align: right;
  }
  
  .modalButton {
    background-color: rgb(206, 154, 255);    
    color: white;
    margin-left: 10px;
  }
  
  .modalButton:hover {
    background-color: rgb(206, 154, 255);  }
  