/* Loading.module.css */

.loadingScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(206, 154, 255); /* Yeni arka plan rengi */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  opacity: 1;
  animation: fadeOut 1s forwards;
}

.loadingContent {
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 33px;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0,5;
  }
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
}

p {
  margin-top: 10px;
  font-size: 24px;
  animation: fadeInOut 1s infinite;
}
