.container {
  max-width: 1200px;
  margin: auto;
  padding: 10px;
  margin-top: 60px; /* Header yüksekliği kadar bir üst boşluk */
  margin-bottom: 60px; /* Footer yüksekliği kadar bir alt boşluk */
  border-radius: 20px;
}

.lightboxContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  border-top-width: 10px; /* Üst kısmı 10px yüksekliğinde */
}

.lightboxContent {
  position: relative;
  background-color: white; /* Lightbox içeriği için beyaz arka plan */
  padding: 2px;
  max-width: 100%;
  overflow: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Hafif bir gölgelendirme */
  border-radius: 10px;
  min-width: 100%;
}

.closeButton {
  position: absolute;
  color: rgb(184, 67, 67);
  background-color: rgb(240, 229, 237);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  z-index: 9999;
}

.mediaGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  border-radius: 10px;
}

.mediaItem {
  flex: 0 0 calc(33.33% - 10px); /* 3 sütunlu grid için */
  position: relative;
  width: 100%;
  display: block;
  border: 1px solid rgb(43, 1, 49); /* Kenarlık */
  transition: transform 0.2s ease-in-out; /* Geçiş efekti */
  border-radius: 10px; /* Köşeleri yuvarlatıyoruz */
  cursor: pointer;
}

.mediaItem:hover {
  transform: scale(1.05); /* Fare üzerine gelince büyüme efekti */
}

.error {
  color: red;
  text-align: center;
  margin-top: 20px;
}

@media (max-width: 1200px) {
  .mediaItem {
    flex: 0 0 calc(50% - 10px); /* 2 sütunlu grid */
  }
}

@media (max-width: 768px) {
  .container {
    padding: 5px;
  }

  .lightboxContent {
    max-width: 90%;
    max-height: 90%;
  }

  .mediaItem {
    flex: 0 0 calc(50% - 10px); /* 2 sütunlu grid */
  }
}

@media (max-width: 480px) {
  .lightboxContent {
    max-width: 95%;
    max-height: 95%;
  }

  .mediaItem {
    flex: 0 0 calc(100% - 10px); /* 1 sütunlu grid */
  }
}

.videoWrapper {
  position: relative;
  border-radius: 10px;

}

.videoIcon {
  position: absolute;
  top: 3px;
  left: 1px;
  font-size: 11px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 4px;
  z-index: 1;
}



@media (max-width: 768px) {
  .customImageItem {
    flex: 1 0 calc(50% - 1px);
  }
}