/* Header.module.css */

.header {
  background-color: rgb(206, 154, 255); /* Mor arka plan rengi */
  color: #ffffff; /* Beyaz renk */
  padding: 0px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000; /* İstediğiniz bir değer verebilirsiniz */
  right: 5px;
}

.navbar {
  display: flex;
  justify-content: center;
}

ul {
  list-style-type: none;
  padding: 0;
  display: flex;
}

li {
  margin: 0 10px;
}

.link {
  display: flex;
  align-items: center;
  color: #ffffff; /* Beyaz renk */
  text-decoration: none;
  font-size: 15px; /* Yazı boyutu */
}

.icon {
  margin-right: 5px;
  font-size: 18px; /* Icon boyutu */
}

@media (max-width: 600px) {
  .navbar {
    flex-direction: column;
    align-items: center;
  }

  li {
    margin: 10px 10px;
  }
}
