.circularProgressContainer {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px; /* Opsiyonel: Üst boşluğu ayarlayabilirsiniz */
    margin-right: 8px; /* Sağ boşluğu ayarlayabilirsiniz */
    margin: 18px;
  }
  
  .circularProgressLabel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  