/* Menü Konteyneri Stilleri */
.menuContainer ul {
  display: flex;
  flex-direction: column; /* Öğeleri alt alta sıralamak için flex-direction kullanıyoruz */
  max-width: 800px;
  margin: 100px auto 20px; /* Üst ve alt margin ayarları */
  padding: 20px;
  padding-top: 30px;
  background-color: #b300fa0e;
  border: 1px solid #4a0b59cc;
  border-radius: 10px;
  position: relative;
  z-index: 10; /* Header'dan üstte olmasını sağlamak için z-index ekledik */
}

/* Menü Öğeleri Stilleri */
.menuItem {
  margin-bottom: 12px;
  padding: 10px;
  border-radius: 4px;
  background-color: rgb(206, 154, 255);
  box-shadow: 0 2px 4px rgba(93, 2, 85, 0.1); 
  transition: background-color 0.3s ease;
}

.menuItem:hover {
  background-color: #e85ce1d7;
}

.menuItem strong {
  font-size: 18px;
  color: #690086;
}

.menuItem-description {
  color: #530077;
}

/* Tabak Wrapper ve Tabak Stilleri */
.plateWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px; /* Menü öğelerinden sonra boşluk */
}

.plate {
  position: relative;
  display: flex; 
  flex-direction: column;
  height: 90px; /* Ayarlanmış yükseklik */
  width: 90px;  /* Ayarlanmış genişlik */
  background-color: #e4e4e4;
  border-radius: 50%;
  justify-content: center;
  font-size: 20px; /* Ayarlanmış font boyutu */
  box-shadow: 2px 2px 8px 2px #000;
  padding: 20px; /* Padding değerini ayarla */
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.inner_plate {
  position: relative;
  border-radius: 50%;
  height: 85px; /* Ayarlanmış yükseklik */
  width: 85px;  /* Ayarlanmış genişlik */
  border: #dddddd solid 2px;
  background-color: #d2d2d2;
  font-size: 1rem;
  box-shadow: 0 0 10px 10px #cfcfcf;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 10; /* Correct z-index */
}

.inner_plate h3 {
  margin: 0;
  font-size: 1.2rem;
  color: #333;
}

.inner_plate::after {
  content: "Afiyet Olsun!";
  position: absolute;
  background-color: #e3e3e3;
  height: 40%;
  width: 20%;
  display: block;
  border-radius: 50%;
  transform: rotate(34deg);
  right: 50%; /* Adjusted position */
  top: 20%; /* Adjusted position */
  font-size: 1rem; /* Adjust font-size */
  box-shadow: -.6em .2em 1em 1em #e3e3e3;
  z-index: 1; /* Correct z-index */
}
