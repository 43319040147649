/* Footer.module.css */

.footer {
  background-color: rgb(206, 154, 255); /* Mor arka plan rengi */
  color: white; /* Beyaz yazı rengi */
  padding: 10px; /* Dolgu alanı */
  display: flex; /* İçeriği yatay olarak hizalar */
  flex-wrap: wrap; /* İçeriği sarma özelliği ekler */
  justify-content: center; /* İçeriği ortalamak için */
  text-align: center; /* Metni ortalamak için */
  position: fixed; /* Footer'ı sabit konumda tutar */
  bottom: 0; /* Sayfanın altına hizalar */
  width: 100%; /* Tam genişlik */
  z-index: 1000; /* Diğer öğelerden üstte olmasını sağlar */
  right: 5px;

}

.footerText {
  display: flex; /* İçeriği yatay olarak hizalar */
  align-items: center; /* Öğeleri dikeyde hizalar */
  margin: 5px; /* Kenar boşluğu */
  font-size: 15px;
}

.footerText span {
  margin-right: 5px; /* Metin arasına sağ kenar boşluğu ekler */
}

@media (max-width: 600px) {
  .footerText {
    flex-wrap: wrap; /* Küçük ekranlarda içeriği sarmalar */
    justify-content: center; /* Küçük ekranlarda içeriği ortalar */
  }
}
