.container {
  max-width: 100%;
  padding: 20px;
  margin: 60px auto 60px; /* Header için 80px üst boşluk, footer için 60px alt boşluk bırak */
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .container {
    max-width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

.section {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #b300fa0e;
  border-radius: 8px;
  text-align: center;
}

.section textarea {
  width: 100%; /* Genişliği %100 olarak ayarla */
  max-width: 100%; /* Maksimum genişlik %100 */
  height: auto; /* Otomatik yükseklik */
  max-height: 200px; /* Maksimum yükseklik belirle, gerektiğinde değiştirilebilir */
  padding: 10px;
  box-sizing: border-box;
  margin-top: 10px; /* Not alanı ile diğer içerikler arasına boşluk bırak */
}

.section button {
  margin-top: 10px;
}
